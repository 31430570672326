<template>
  <v-flex xs12 :md3="!fullscreen" class="pa-2 mb-6">
    <v-card elevation="24">
      <v-system-bar lights-out></v-system-bar>
      <v-carousel
        :continuous="false"
        :cycle="true"
        :show-arrows="true"
        delimiter-icon="mdi-minus"
        height="250"
      >
        <v-carousel-item v-for="(slide) in career.images" :key="slide">
          <v-sheet height="100%" tile>
            <v-row class="fill-height" align="center" justify="center">
              <div class="display-3">
                <v-img max-height="260" contain :src="slide"></v-img>
              </div>
            </v-row>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
      <v-list class="pa-0 ma-0" dense>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="fonte" style="font-size: 14pt;"
              >Plano de Ativação</v-list-item-title
            >
            <v-list-item-subtitle class="fonte font-weight-thin orange--text">{{
              career.description
            }}</v-list-item-subtitle>
            <v-list-item-subtitle class="green white--text px-1 "> Ganhe {{ career.token_earn }} MB tokens </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              v-if="!priceless"
              @click="openPurchase({ ...career, purchase_type: 'career' })"
              class="mt-3"
              :color="$theme.primary"
              outlined
              dark
            >
              <span class="fonte">R$ {{ career.price }}</span>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
          <v-divider></v-divider>
              
          <h3 v-if="!priceless" class="fonte text-center my-2">BENEFÍCIOS</h3>
          <v-divider></v-divider>
          <template v-for="(item, index) in career.system_actions">
          <!-- <template v-for="(item, i) in teste_actions"> -->
            <v-list-item v-if="!priceless" :key="item._id">
              <v-list-item-content>
                <h5 class="fonte text-center orange--text">{{ item.description }}</h5>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon size="15" color="green">mdi-check</v-icon>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="index"></v-divider>
          </template>
      </v-list>
    </v-card>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      teste_actions: ['teste', 'teste3', 'teste4']
    }
  },
  props: ["career", "fullscreen", "priceless"],
  methods: {
    ...mapActions(["openPurchase"])
  }
};
</script>
